@media screen and (min-width: 900px) {
    .loginCont h2 {
        font-size: 25px;
       
    }
    .loginCont .italicFont {
        font-size: 10px;
       
    }
    .adjustBlueside{
        height: 110vh;
    }
    .signupMobileTitle{
        display: none;
    }
    .passwordReq{
        height: 28vh;
    }
   
}

@media screen and (min-width: 1200px) {
    .loginCont h2 {
        font-size: 33px;
        
    }
    .loginCont .italicFont {
        font-size: 13px;
    }
    .adjustBlueside{
        height: 110vh;
    }
    .signupMobileTitle{
        display: none;
    }
    .passwordReq{
        height: 28vh;
    }
}



@media screen and (max-width: 900px) {
    .loginCont h2 {
        font-size: 22px;
       
    }
    
    .adjustBlueside{
        height: 110vh;
    }
    .signupMobileTitle{
        display: none;
    }
    .passwordReq{
        height: 28vh;
    }
    .loginCont .italicFont {
        font-size: 10px;
       
    }
    
}
@media screen and (max-width: 765px) {
    .adjustBlueside{
        height: 8vh;
    }
    .loginLogo{
        margin-top: 20px;
        left: 15%;
    }
    .loginLogo img {
        width: 30%;
        height: 50%;
        display: block;
        object-fit: cover;
        object-position: center;
        margin-left: 20px;
    }
    .signupMobileTitle{
        color: white;
        display: block;
        text-align: center;
        font-weight: 800;
        margin-bottom: 0;
        text-transform: uppercase;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .signupWebTitle{
        display: none;
    }
    .footer_logo{
        padding-top: 0px;
    }
    .passwordReq{
        height: 35vh;
    }
}