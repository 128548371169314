@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}

/* ************************************added by accountatWs team for client onboarding cards************************************ */
.onBoarding .icon-box {
    padding: 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
  }
  .onBoarding .icon-box::before {
    content: "";
    position: absolute;
    background: #e1f0fa;
    right: -60px;
    top: -60px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    transition: all 0.3s;
    z-index: -1;
  }
  .onBoarding .icon-box:hover::before {
    background: #4b5ca9c5;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
  .onBoarding .icon {
    margin: 0 auto 0px 30px;
    padding-top: 10px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    /* background: #3498db; */
    transition: all 0.3s ease-in-out;
  }
  .onBoarding .icon i {
    font-size: 36px;
    line-height: 1;
    color: #464647;
  }
  .onBoarding .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
    color: #464647;
  }
  .onBoarding .title a {
    color: #111;
  }
  .onBoarding .description {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .onBoarding .icon-box:hover .title a, .onBoarding .icon-box:hover .description {
    color: #fff;
  }
  .onBoarding .icon-box:hover .icon {
    background: #fff;
  }
  .onBoarding .icon-box:hover .icon i {
    color: #464647;;
  }

  

/* ******************************************************************************************************************** */


.fc-scrollgrid-sync-table tbody tr{
    height: 150px;
}
.fc .fc-button-primary{
    color:#5060AB !important;
}
.fc-prev-button{
    background-color: snow !important;
    border: none !important;
}
.fc-next-button{
    background-color: snow !important;
    border: none !important;
}
.fc-addEventBtn-button{
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #5060AB !important;
    background: #fff !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    outline: none !important;
    border: 0 !important;
    border-radius: 50px !important;
    transition: all 0.3s linear 0s !important;
    margin-top: 5px !important;
    margin-left: 24px !important;
    margin-bottom: 5px !important;
}
.fc-today-button{
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #5060AB !important;
    background: #fff !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    outline: none !important;
    border: 0 !important;
    border-radius: 50px !important;
    transition: all 0.3s linear 0s !important;
    margin-top: 5px !important;
    margin-left: 24px !important;
    margin-bottom: 5px !important;
    opacity: 1 !important;
}

.fc-today-button:hover {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #fff !important;
    background: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat padding-box !important;
    outline: none !important;
    border: 0 !important;
    border-radius: 50px !important;
    margin-top: 5px !important;
    margin-left: 24px !important;
    margin-bottom: 5px !important;

}
.fc-addEventBtn-button:hover {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #fff !important;
    background: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat padding-box !important;
    outline: none !important;
    border: 0 !important;
    border-radius: 50px !important;
    margin-top: 5px !important;
    margin-left: 24px !important;
    margin-bottom: 5px !important;

}
.getAppointmentNameUIcss{
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    color: #3c4043;
    max-height: 56px;

}
.getAppointmentDateTimeUIcss{
    font-family: Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .2px;
    line-height: 20px;
    color: #3c4043;
}


.asterisk_input:after {
    content: "*";
    color: #e32;
    position: absolute;
    /* margin: 0px 0px 0px -20px; */
    font-size: 17px;
    padding: 0 5px 0 0;
  }
  .asterisk_date:after {
    content: "*";
    color: #e32;
    position: absolute;
    /* margin: 0px 0px 0px -20px; */
    font-size: 17px;
    padding: 0 5px 0 0;
  }
  .asterisk_dropdown:after {
    content: "*";
    color: #e32;
    position: absolute;
    margin: 0px 0px 0px 3px;
    font-size: 17px;
    padding: 0 5px 0 0;
  }

/* ************************************added by cpasage team************************************ */
.page-contain {
    /* display: flex; */
    /* min-height: 100vh; */
    align-items: center;
    justify-content: center;
    /* background: #e7f3f1; */
    border: 0.75em solid white;
    padding: 2em;
    font-family: "Open Sans", sans-serif;
  }
  
  .data-card {
    display: flex;
    flex-direction: column;
    /* max-width: 20.75em;
    min-height: 20.75em; 
    height: 15rem; */
    overflow: hidden;
    border-radius: 0.5em;
    text-decoration: none;
    background: white;
    box-shadow: 0px 0px 5px 0px #00000029;
    /* margin: 1em; */
    /* padding: 2.75em 2.5em; */
    /* box-shadow: 0 1.5em 2.5em -0.5em rgba(0, 0, 0, 0.1); */
    transition: transform 0.45s ease, background 0.45s ease;
  }
  .data-card h3 {
    color: #2e3c40;
    font-size: 3.5em;
    font-weight: 600;
    line-height: 1;
    padding-bottom: 0.5em;
    margin: 0 0 0.142857143em;
    /* border-bottom: 2px solid #753bbd; */
    transition: color 0.45s ease, border 0.45s ease;
  }
  .data-card h4 {
    color: #627084;
    text-transform: uppercase;
    font-size: 1.125em;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.1em;
    margin: 0 0 1.777777778em;
    transition: color 0.45s ease;
  }
  .data-card p {
    opacity: 0;
    color: #ffffff;
    font-weight: 600;
    line-height: 1.8;
    margin: 0 0 1.25em;
    transform: translateY(-1em);
    transition: opacity 0.45s ease, transform 0.5s ease;
  }
  .data-card .link-text {
    display: block;
    color: #753bbd;
    font-size: 1.125em;
    font-weight: 600;
    line-height: 1.2;
    margin: auto 0 0;
    transition: color 0.45s ease;
  }
  .data-card .link-text svg {
    margin-left: 0.5em;
    transition: transform 0.6s ease;
  }
  .data-card .link-text svg path {
    transition: fill 0.45s ease;
  }
  .data-card:hover {
    background: #4b5ca9c5;
    transform: scale(1.02);
  }
  .data-card:hover h3 {
    color: #ffffff;
    border-bottom-color: #a754c4;
  }
  .data-card:hover h4 {
    color: #ffffff;
  }
  .data-card:hover p {
    opacity: 1;
    transform: none;
  }
  .data-card:hover .link-text {
    color: #ffffff;
  }
  .data-card:hover .link-text svg {
    -webkit-animation: point 1.25s infinite alternate;
            animation: point 1.25s infinite alternate;
  }
  .data-card:hover .link-text svg path {
    fill: #ffffff;
  }
  
  @-webkit-keyframes point {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0.125em);
    }
  }
  
  @keyframes point {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0.125em);
    }
  }
/* ====================================================================================== */

.tooltip1 {
   position: relative;
}

.tooltiptext1 {
  visibility: hidden;
  width: 120px;
  background-color: #8094f7ad;
  color: #383636;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltiptext1::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #8094f7ad transparent;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

/* *************************input model placeholde**************************** */
input::placeholder {
    color: #a2a2a2;
}

.form-group input[type="text"]{
        padding: 8px 10px;
        background: #fff;
        border: 0;
        font-size: 12px;
        cursor: pointer;
        line-height: 20px;
        width: 100%;
   }

   /*********************** contact us page **********************************/
.contact_form{
    width: 100%;
    padding: 80px 15px 0 15px;
}
.right_contact_form .form-group{
    padding-bottom: 15px;
}
.right_contact_form .form-group .form-control{
    outline: none;
    border: 0;
    border-bottom: solid 1px #ddd;
    padding: 10px 20px;
    font-size: 15px;
    border-radius: 0;
    background-color: transparent;
    color: #111;
}
.right_contact_form .form-group .form-control::placeholder{
    color: #aaa;
}
.right_contact_form .form-group .form-control:focus{
    outline: none;
    box-shadow: none;
}
.right_contact_form .form-group .form-control.placeholder{
    color: #aaa;
}
.right_contact_form .form-group select.form-control option{
    color: #333;
}
.right_contact_form .btn-secondary{
    margin-top: 30px;
    margin-left: 20px;
    color: #4c5da9;
}
.right_contact_form .btn-secondary:hover{
    color: #fff;
}
.item-center{
    align-items: center;
}
.left_contact_name P{
    font-size: 18px;
    color: #606060;
    margin: 0;
    padding-top: 10px;
    line-height: 1.5;
}
.cpa_page_title{
    font-size: 45px;
    font-weight: 700;
    color: #3d3d3d;
    margin: 0;
    padding: 0;
    line-height: 1;
}
.custom_select{
    position: relative;
}
.custom_select::after{
    content: "\f107";
    font-family: 'FontAwesome';
    position: absolute;
    top: 7px;
    right: 15px;
    font-size: 22px;
    color: #777;
    pointer-events: none;
}


body {
    font-family: "Poppins", Sans-serif;
}
/* CPASAGE - to remove the blue line on over */
a:hover,
a:active,
a:focus,
input:hover,
input:active,
input:focus,
button:hover,
button:active,
button:focus 
form-control:focus
button {
  outline: none !important;
}
a {
    color: #5060AB;
    font-weight: 500;
}

a:hover,
a:active,
a:focus {
    color: #5060AB;
}



::placeholder {
    font-size: 12px;
}

::-webkit-scrollbar{
    width: 6px;
    height: 6px;
    background-color: #f7f7f7;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
}
.form-control:focus{
    /*border-color: #66afe9b5;*/
    box-shadow: none; 
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mr-10 {
    margin-right: 10px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-40 {
    margin-right: 40px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-40 {
    margin-left: 40px;
}

.ml-15 {
    margin-left: 15px;
}

.pr-0 {
    padding-right: 0;
}

.pl-0 {
    padding-left: 0;
}

.pt-0 {
    padding-top: 0;
}

.page-wrapper {
    width: 100%;
   /* display: flex;*/
    flex-wrap: nowrap;
}
/*CPA SAGE TEAM - added to reduce the white space*/
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding-left: 0px;
}

.left-nav-container {
    /* CPA SAGE TEAM - width changed to accomodate the client name change*/
    /*width: 121px; height: 100vh;
    background-color: #283B72;
    background-color: #283B72;
    position: relative;*/
    width: 40px;
    height: 100%;
    position: fixed;
    background: linear-gradient(90deg,#4b5ca9 40%,#4b5ca9c5 100%);
    top: 0px;
    left: 0px;
   font-size: 12px;
}


.nav-logo a {
    width: 100%;
    display: block;
    padding: 20px 10px;
}

.nav-logo a img {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto
}

.nav-logo a span {
    display: block;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
    padding-top: 10px;
}

.left-nav-item {
    width: 100%;
    /*max-height: calc(100vh - 95px);*/
    overflow-y: auto;
}

.menu-item a {
    width: 100%;
    display: block;
    padding: 15px 10px;
    transition: all 0.3s linear 0s;
}

.menu-item a img {
    width: 21px;
    height: auto;
    margin: 0 auto
}

.menu-item a img.w-22px {
    width: 22px;
}

.menu-item a img.w-24px {
    width: 24px;
}

.menu-item a img.w-32px {
    width: 32px;
}

.menu-item a span {
    display: block;
    width: 100%;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    font-size: 13px;
    padding-top: 5px;
    transition: all 0.3s linear 0s;
}

.menu-item a img.inactive {
    display: block;
}

.menu-item a img.active {
    display: none;
}

.menu-item a:hover img.active, .menu-item a.active img.active {
    display: block;
}

.menu-item a:hover img.inactive, .menu-item a.active img.inactive {
    display: none;
}

.menu-item a:hover, .menu-item a.active {
    background-color: #ffffff;
    text-decoration: none;
}

.menu-item a:hover span, .menu-item a.active span {
    color: #283B72;
    font-weight: 500;
    font-size: 15px;
}

.menu-item-wrapper {
    position: relative;
    z-index: 9;
}
/* CPA SAGE TEAM - NOT INCLUDED IN OUR CODE*/ 
.background-circle-animation {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: transparent;
    z-index: 0;
    border-radius: 0;
}

.circle-opacity {
    opacity: 0.1;
}

.bubbleBase {
    position: absolute;
    top: 5%;
    left: -30px;
}

.bubbleBase img {
    width: 160px;
}

.left-nav-container:hover .background-circle-animation .bubbleBase {
    transform: translate(0px, -200px);
    transition: transform 0.5s linear 0s;
}

.bubbleBase2 {
    position: absolute;
    top: 35%;
    left: -28px;
}

.bubbleBase2 img {
    width: 60px;
}

.left-nav-container:hover .background-circle-animation .bubbleBase2 {
    transform: translate(-100px, 0px);
    transition: transform 0.s linear 0s;
}

.bubbleBase3 {
    position: absolute;
    top: 50%;
    right: -50px;
}

.bubbleBase3 img{
    width: 100px;
}

.left-nav-container:hover .background-circle-animation .bubbleBase3 {
    transform: translate(150px, 0px);
    transition: transform 0.3s linear 0s;
}

.bubbleBase4 {
    position: absolute;
    top: 65%;
    left: -40px;
}

.bubbleBase4 img {
    width: 80px;
}

.left-nav-container:hover .background-circle-animation .bubbleBase4 {
    transform: translate(-150px, 0px);
    transition: transform 0.3s linear 0s;
}

.bubbleBase5 {
    position: absolute;
    bottom: -60px;
    left: -60px;
}

.bubbleBase5 img {
    width: 140px;
}

.left-nav-container:hover .background-circle-animation .bubbleBase5 {
    transform: translate(-150px, 0px);
    transition: transform 0.3s linear 0s;
}*/

/*CPASage - for client login - top nav - left style with Icon*/

.fa-stack-cpacage-topleftnav {
    height: 0em;
}


.right-body-content-wrapper {
    /*width: calc(100% - 120px);
    height: 100vh;
    background-color: #ffffff;*/
    background-color: #ffffff;
    /*margin-left: 40px;*/
    box-sizing: content-box;
}

.right-top-header:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.right-top-header {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #ffffff;
     border-bottom: solid 1px #ddd;
    z-index: 990;
    padding: 10px 1px;
    justify-content: space-between;
    position: relative;
}

.top-left-menu-item-container {
    display: flex;
    flex-wrap: nowrap;
    /*width: calc(100% - 270px);*/
    /*    overflow-x: auto;*/
}

.top-left-menu-item {
    list-style-type: none;
    /*padding-bottom: 20px;*/
    padding-left: 20px;
}

.top-left-menu-item .flecx{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 14px;
    color: #606060;
    font-weight: 400;
    text-decoration: none;
    padding: 5px;
    transition: all 0.3s linear 0s;
    /*min-width: 80px;*/
}

.top-left-menu-item .flecx .menu-icon {
    width: 35px;
    height: 35px;
    background-color: #F5F6FF;
    border-radius: 50%;
    margin-right: 10px;
    padding: 7px;
    /*    box-shadow: 0px 0px 6px 0px #00000029;*/
    /*transition: all 0.3s linear 0s;*/
}

.top-left-menu-item .flecx .menu-icon img {
    width: 100%;
    height: 100%;
}

.top-left-menu-item .flecx .menu-icon img.inactive {
    display: block;
}

.top-left-menu-item .flecx .menu-icon img.active {
    display: none;
}

.top-left-menu-item .flecx span {
    position: relative;
    color: white;
}

.top-left-menu-item .flecx span::after {
    content: "";
    display: block;
    width: 0%;
    height: 1.1px;
    background-color: white;
    position: absolute;
    bottom: -1px;
    left: 0;
    transition: all 0.3s linear 0s;
}

.top-left-menu-item a:hover, .top-left-menu-item a.active {
    color: #ffffff;
}

.top-left-menu-item a:hover .menu-icon, .top-left-menu-item a.active .menu-icon {
    background-color: #ffffff;
}

.top-left-menu-item a:hover .menu-icon img.inactive, .top-left-menu-item a.active .menu-icon img.inactive {
    display: none;
}

.top-left-menu-item a:hover .menu-icon img.active, .top-left-menu-item a.active .menu-icon img.active {
    display: block;
}

.top-left-menu-item a:hover span::after {
    width: 100%;
    background-color: #ddd;
}

.top-left-menu-item a.active span::after, .top-left-menu-item a.active:hover span::after {
    width: 100%;
    background-color: #ffffff;
}

.top-right-menu-item-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}

.top-right-menu-item {
    padding: 5px;
    position: relative;
}

.notifi {
    position: absolute;
    width: 36px;
    top: -14px;
    right: -7px;
}

.countCon {
    position: absolute;
    top: -4px;
    font-size: 11px;
    left: 47px;
    color: #fff;
}

.countSec p {
    color: #fff;
    font-size: 8px;
    position: absolute;
}

.top-right-menu-item a {
    /*width: 50px;
    min-width: 50px;
    height: 35px;*/
    display: block;
    padding: 14px;
    text-align: center;
    /*background: linear-gradient(90deg,#4b5ca9 40%,#4b5ca9c5 100%);*/
    color: #ffffff;
    transition: all 0.3s linear 0s;
    /*box-shadow: 0px 0px 6px 0px #00000029;*/
    border-radius: 50px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}

.top-right-menu-item a img {
    height: 100%;
    width: auto;
    margin: 0 auto;
}

.top-right-menu-item a img.inactive {
    display: block;
}

.top-right-menu-item a img.active {
    display: none;
}

/*.top-right-menu-item a:hover, .top-right-menu-item a.active {
    background-color: #4B5CA9;
    color: #ffffff;
}*/

.top-right-menu-item a:hover img.inactive, .top-right-menu-item a.active img.inactive {
    display: none;
}

.top-right-menu-item a:hover img.active, .top-right-menu-item a.active img.active {
    display: block;
}

.body-content-wrapper {
    width: 100%;
    background-color: #ffffff;
    /*height: calc(100vh - 75px);*/
    overflow-y: auto;
    padding: 15px 15px;
}

.heading-box {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.heading-box h6 {
    font-size: 25px;
    color: #424242;
    line-height: 1.3;
    font-weight: 600;
    margin: 0;
    padding-right: 15px;
}

.heading-box img {
    width: 60px;
    /* height: 25px;*/
    display: block;
}
/* CPASAGE TEAM - removing bg color for notesbox and making the notebox open always at bottom*/
.notesBox textarea {
    width: 100%;
    height: auto;
    color: #071441;
    border-radius: 3px;
    border: 0;
    padding: 15px;
    resize: vertical;
    outline: none;
    
}

.contact-box {
    width: 100%;
    height: 100%;
    padding: 20px 20px;
    border-radius: 11px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px #00000029;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    transition: all 0.3s linear 0s;
    position: relative;
    position: relative;
}

.contact-box::after {
    content: "";
    display: block;
    width: 0px;
    height: 2px;
    background-color: #4B5CA9;
    position: absolute;
    bottom: 0;
    left: 18px;
    transition: all 0.3s linear 0s;
}

.contact-box:hover::after, .contact-box.active::after {
    width: 30px;
}

.contact-box img {
    width: 25px;
    margin-right: 10px;
    padding-left: 16px;
}

.contact-box img.inactive {
    display: block;
}

.contact-box img.active {
    display: none;
}

.contact-box p {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    line-height: 1;
    margin: 0;
    transition: all 0.3s linear 0s;
    word-break: break-word;
}

.contact-box:hover, .contact-box.active {
    text-decoration: none;
}

.contact-box:hover img.inactive, .contact-box.active img.inactive {
    display: none;
}

.contact-box:hover img.active, .contact-box.active img.active {
    display: block;
}

.contact-box:hover p, .contact-box.active p {
    color: #4B5CA9;
}

.notesBox {
    width: 100%;
}



/*.my-task-box {
    padding-top: 20px;
}*/

input[type="checkbox"] :disabled span {
    color: green;
}

.custom-checkbox-radio .label--checkbox, .custom-checkbox-radio .label--radio {
    position: relative;
    cursor: pointer;
    display: block;
    color: #2c2c2c;
    font-size: 12px;
    margin: 0;
    line-height: 1.5;
    font-weight: 400;
}



.custom-checkbox-radio .checkbox:after {
    content: "";
    position: absolute;
    top: -3px;
    left: -2px;
    width: 20px;
    height: 20px;
    background: #EEF3FF;
    border: solid 0px #555;
    cursor: pointer;
    border-radius: 50%;
}

.custom-checkbox-radio .checkbox:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg) scale(0, 0);
    -moz-transform: rotate(-45deg) scale(0, 0);
    -ms-transform: rotate(-45deg) scale(0, 0);
    -o-transform: rotate(-45deg) scale(0, 0);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 2px;
    top: -2px;
    z-index: 1;
    width: 16px;
    height: 8px;
    border: solid 2px #707070;
    border-top-style: none;
    border-right-style: none;
}

.custom-checkbox-radio .checkbox:checked:before {
    -webkit-transform: rotate(-45deg) scale(1, 1);
    -moz-transform: rotate(-45deg) scale(1, 1);
    -ms-transform: rotate(-45deg) scale(1, 1);
    -o-transform: rotate(-45deg) scale(1, 1);
    transform: rotate(-45deg) scale(1, 1);
}

.custom-checkbox-radio .checkbox:checked::after {
    background-color: #EEF3FF;
    border-color: #EEF3FF;
}

.task-container {
    /* max-height: 300px; */
    overflow-y: auto;
}

.task-checkbox {
    width: 32px;
}
/* CPASAGE added textarea pading for add todo */

/* CPASAGE TEAM - added box shadow as border*/
.task-item {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 3px 0;
}
/* CPA SAGE TEAM - removing bg color for task-details, repetative code deleted*/
.task-details {
    width: calc(100% - 32px);
    padding: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    border-radius: 5px;
}

.flexStart {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.left-task-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.task-icon {
    width: 40px;
    height: 40px;
    background-color: #4B5CA9;
    padding: 0px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
}

.task-icon img {
    width: 100%;
    height: 100%;
    display: block;
}
/* CPA SAGE TEAM - adjusted width size to occupy available space */
.task-content {
    width: auto;
}
/* CPA SAGE TEAM - made font size 12 */
.task-content h6 {
    color: #091E4A;
    font-size: 12px;
    /*font-weight: 600;*/
    margin: 0;
    padding: 0;
}

.task-content p {
    font-size: 11px;
    color: #5C6B8A;
    font-weight: 400;
    margin: 0;
    padding-top: 5px;
}

.task-content p i {
    font-weight: 500;
    color: #5C6B8A;
}

.task-dots {
    width: 80px;
}

.task-dots span {
    width: 5px;
    height: 5px;
    background-color: #B20202;
    display: inline-block;
    margin: 1px;
    border-radius: 50%;
}

.complete span {
    background-color: green;
}

.complete p {
    color: green;
}

.task-status {
    font-size: 11px;
    color: #B20202;
    padding-right: 15px;
}
.take-due-data {
    font-weight: 600;
    color: #091E4A;
    font-size: 11px;
    text-align: right;/* CPASAGE TEAM - addded*/
    margin-bottom: 25px;
}

.chat-header {
    width: 100%;
    /* margin-left: -2px; */
    background-color: #ffffff;
    padding: 10px 13px;
    text-align: center; 
    position: relative;
    border-radius: 11px 11px 0 0;
    /* border-bottom: 1px solid; */
    box-shadow: 0px 0px 5px 0px #00000029;
}



/* CPASAGE TEAM CHANGE - removed the background color to match with our theme of embedding*/

.chat-footer-container {
    width: 100%;
    padding: 10px 10px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px #00000029;
    /*border: 2px solid #f5f5f5;
    border-radius: 0 0 5px 5px;*/
}

.chat-footer-form {
    position: relative;
    width: 103%;
    margin: 0;
    padding-left: 0;
}
/* CPA SAGE TEAM - added textara to make it like a chat box*/
.chat-footer-form textarea{
  width: 92%;
  /*padding: 15px;
  margin: 5px 0 22px 0;*/
  border: none;
  background: #fff;
  resize: none;
  min-height: 10px;
  word-break: break-word;
  outline: none;
  height: 48px;
}
.chat-footer-form textarea:focus{
    height: 150px;
    transition: all 0.5s ease 0s;

}
/* CPA SAGE TEAM - edited placeholder defalut styling */
.chat-footer-form textarea::placeholder {
    font-size: 14px;
    color: #2c2c2c;
    font-weight: 400;
}

.chat-footer-form input {
    width: 100%;
    background-color: #ffffff;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #616161;
    display: block;
    padding: 10px 55px 10px 15px;
    border: 0;
    outline: none;
    line-height: 20px;
}

.chat-footer-form button {
    padding: 0px 13px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    line-height: 42px;
    background: #6e7bb9;
    outline: none;
    border: 0;
    border-radius: 3px;
    transition: all 0.3s linear 0s;
    position: absolute;
    top: 0px;
    right: 28px;
}
/* CPASAGE TEAM CHANGE - To make the scroll bar at bottom when the chat box is loaded and make the UI looks embeded */
.chat-body-container {
    width: 100%;
    padding: 10px;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px #00000029;
    height: 350px;
    overflow-y: auto;
    /*border: 2px solid #f5f5f5;
    border-radius: 0 0 5px 5px;*/
    display: flex;
    flex-direction: column-reverse;
    word-break: break-word;
    
}

/* CPASAGE TEAM - to bring chat message at the bottom of the page*/

.panel-default-cpasage-client {
 position: fixed;
 bottom: 1px;
 right: 16px;
 width: 375px;
}
/* CPASAGE TEAM - to bring chat message at the bottom of the page*/

.panel-default-cpasage-team {
 position: fixed;
 bottom: 1px;
 right: 390px;
 width: 375px;
}



.no-msg-fond {
    width: 100%;
    height: 100%;
}

.no-msg-fond p {
    margin: 0;
    font-size: 13px;
    font-weight: 600;
    color: #616161;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}

.msg-container {
    width: 100%;
    height: auto;
}

.msg-items {
    width: 99%;
    position: relative;
    /* padding-left: 50px; */
    padding-bottom: 25px;
}

.user-profile {
    width: 35px;
    height: 35px;
    line-height: 35px;
    color: #4B5CA9;
    font-size: 12px;
    font-weight: 600;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
    border: solid 1px #eee;
    position: absolute;
    top: 0;
    /* left: 0; */
    text-align: center;
    overflow: hidden;
    border-radius: 50%;
    text-transform: uppercase;



}

/* **************************removed by cpasage employee***************************/
/*
.msg-items::before {
    content: "";
    position: absolute;
    height: 100%;
    top: 0px;
    width: 5px;
    background-color: #d3d8f2;
    left: 15px;
}
*/



/* **************************added by cpasage Team***************************/
.msg-in-arrow{
    content: "";
    position: absolute;
    top: 20px;
    right: 100%;
    margin-top: -10px;
    border-width: 8px;
    border-style: solid;
    height: 1px;
    border-color: transparent #f3f3f3 transparent transparent;
}
/* **************************added by cpasage Team***************************/
.msg-out-arrow{
    content: "";
    position: absolute;
    top: 15px;
    left: 100%;
    margin-top: -5px;
    border-width: 8px;
    border-style: solid;
    height: 1px;
    border-color: transparent transparent transparent #d3d8f2;
}
.msg-box-container {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    background-color: #f3f3f3;
}

.msg-name-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
}
/* CPASAG TEAM CHANGE - made the font size smaller and matching to the time-stamp and the date and added text-allingment to left*/
.msg-name-container h6 {
    font-size: 9px;
    font-weight: 400;
    color: #5C6B8A;
    margin: 0;
    text-align: left;
}

.time-stamp {
    font-size: 9px;
    font-weight: 400;
    color: #5C6B8A;
    margin: 0;
    text-align: right;
}

.time-stamp span {
    padding-left: 3px;
}

.msg-text {
    width: 100%;
    padding-top: 10px;
    white-space: pre-wrap;
}
/* CPASAGE TEAM - changed font size - 12 to marry with active engagement table font size*/
.msg-text p {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.4;
    margin: 0;
}

.msg-items.current-user-msg .user-profile {
    color: #fff;
    background: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat;
}

.msg-items.current-user-msg .msg-box-container {
    background-color: #d3d8f2;
}

.box-col {
    padding-bottom: 10px;
}

.chat-header .bubbleBase {
    top: -60px;
    left: 3%;
}

.chat-header .bubbleBase img {
    width: 80px;
}

.chat-header .bubbleBase2 {
    top: 0px;
    left: 60%;
}

.chat-header .bubbleBase2 img {
    width: 15px;
}

.chat-header .bubbleBase3 {
    bottom: -7px;
    left: 30%;
    top: auto;
    right: auto;
}

.chat-header .bubbleBase3 img {
    width: 25px;
}

.chat-header .bubbleBase4 {
    bottom: -70px;
    left: auto;
    top: auto;
    right: 5%;
}

.chat-header .bubbleBase4 img {
    width: 100px;
}

.chat-container:hover .bubbleBase {
    transform: translate(20px, -50px);
    transition: transform 0.35s linear 0s;
}

.chat-container:hover .bubbleBase2 {
    transform: translate(0px, -50px);
    transition: transform 0.35s linear 0s;
}

.chat-container:hover .bubbleBase3 {
    transform: translate(0px, 50px);
    transition: transform 0.35s linear 0s;
}

.chat-container:hover .bubbleBase4 {
    transform: translate(0px, 100px);
    transition: transform 0.35s linear 0s;
}

/*.contact-detail {
    padding-bottom: 30px;
}*/

.contact-detail p {
margin-bottom: 0rem;
}

.contact-detail img{
    padding-left: 14px;
}

@media screen and (min-width: 1200px) {
    .contact-detail {
        /* padding: 0 200px 30px 0;*/
        padding: 0 30px 30px 0;
    }
    .box-col {
        padding-right: 50px;
    }
}

@media screen and (max-width: 1200px) {
    .msg-wrapper {
        padding-left: 30px;
    }
}

@media screen and (max-width: 767px) {
    .mobile-msg-wrapper {
        padding-left: 30px;
    }
}

/*new-css*/

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fcfcfc;
    min-width: 160px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    
}

.dropdown-content .contHover {
    color: black;
    padding: 7px 16px;
    text-decoration: none;
    display: block;
    font-size: 12px;
    border-bottom: solid 0.5px #ddd;
    border-radius: 4px;
}

.hoverClickImg {
    width: 25px;
    margin-right: 6px;
}
/* CopyIcon Code */

.copyIcon  .active, .copyIcon .inactive {
    position: absolute;
    right: 0;
    display: none;
    width: 50px;
}

/* CopyIcon Code ended */

.labelImg {
    width: 75px;
    /*   height: 16px;
    margin-left: 5px;
    margin-top: 3px;*/
}

/*switch*/

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    margin-bottom: 0;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #fff;
}

/*
input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}
*/

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: green;
    box-shadow: none;
}

.notifiCli {
    position: relative;
}

.notifiCli img {
    width: 33px;
}

.notifiCli .countCon {
    position: absolute;
    top: 4px;
    left: 13px;
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.swichSec {
    padding: 0 10px;
}

.rotate-icon {
    margin-left: 15px;
}

.panel-title {
    position: relative;
}

.panel-group .panel+.panel {
    margin-top: 0;
}

.panel-title::after {
    content: "\f077";
    color: #fff;
    top: 3px;
    right: 0px;
    position: absolute;
    font-family: "FontAwesome"
}

.panel-group .panel {
    margin-bottom: 0;
    padding-left: 0;
    border-radius: 4px;
    border: none;
}

.panel-default {
    border-color: none;
}

.panel-title[aria-expanded="true"]::after {
    content: "\f078";
}

/* .top-right-menu-item .contHover {
    width: 0;
    min-width: 0;
} */

/* CPASAGE - added scroll to the bottom of the page */
.scroll-to-top-cpasage {
    position: fixed;
    right: 2rem;
    bottom: 1rem;
    display: none;
    width: 2.75rem;
    height: 2.75rem;
    text-align: center;
    color: #4b5ca9;
    background: white;
    line-height: 46px;
    scroll-behavior: smooth;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
}


.scroll-to-top-cpasage a:hover {
    color:#4b5ca9;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    text-align: center;
    text-decoration: underline;
}


/*CPASAGE - Notification dropdown*/

.badge-cpasage-notif{
    position: absolute;
    margin-left: -15%;
    margin-top: -17%;
    float:right;
    font-weight: 600;   
}

.badge-cpasage-dropdownnotif{
    position: relative;
    margin-left: -3%;
    margin-top: -113%;
    float: right;
    font-weight: 400
}

.badge-cpasage-count{
    padding: .4em .9em;
    line-height: 2.2;
    border-radius: 5px;
    font-size: 100%;

    

}
.fa-stack{
    height:2em;
    position: relative;
    color: #4b5ca9;

}
.fa-inverse {
    color: #dee2e6;
}

.span .badge-danger {
    position: absolute;
}
.badge {
    font-size: 65%;
    padding: .25em .4em;
}

/* CPA SAGE - notif dropdown change */
.dropdown .dropdown-list .dropdowm-item{
    white-space: normal;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    line-height: 1.3rem;

}
.align-items-center {
    align-items: center!important;
}
.d-flex {
    display: flex!important;
}

.dropdown-menu .show{
    display:block;
}

.dropdown-menu:hover {
display: block;
}

.chevron::before {
	border-style: solid;
	border-width: 0.12em 0.12em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	left: 0.15em;
	position: relative;
	top: 0.15em;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 0.45em;
}
.chevron.right:before {
	left: 0;
	transform: rotate(45deg);
}

.chevron.bottom:before {
	top: 8px;
	transform: rotate(135deg);
    left: 10px;
}

.chevron.left:before {
	left: 0.25em;
	transform: rotate(-135deg);
}

.icon-circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}



.bg-primary {
       background: linear-gradient(90deg,#4b5ca9 40%,#4b5ca9c5 100%);
}


.animated--grow-in, .sidebar .nav-item .collapse {
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-timing-function: transform cubic-bezier(.18,1.25,.4,1),opacity cubic-bezier(0,1,.4,1);
    animation-timing-function: transform cubic-bezier(.18,1.25,.4,1),opacity cubic-bezier(0,1,.4,1);
}

.dropdown-header{
    display:block;
    color:#4b5ca9;
    text-align: center;

}

.dropdowm-item {
    padding:.25rem 1.5rem;
}

.small {
    font-size: 60%;
    font-weight: 400;
}

.cpasage-para-style{
    margin-top: 0;
    margin-bottom: 0rem;
    width: 180px;
    overflow: hidden;
    font-size: 10px;
    text-overflow: ellipsis;
}

.icon-circle {
    height: 2rem;
    width: 2rem;
}

.top-right-menu-item .widthIc .contHover {
    width: 100%;
    min-width: 0;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background: transparent;
    box-shadow: none;
    margin: 0;
    padding: 10px;
    text-align: left;
    font-size: 10px;
    text-transform: inherit;
}

.dropdown-content.widthIc {
    width: 268px;
    padding: 10px 0;
    position: absolute;
    right: -60px;
}

.top-right-menu-item .contHover div {
    float: left;
    position: relative;
}

.top-right-menu-item .contHover div .notifi {
    position: absolute;
    width: 36px;
    top: -7px;
    right: 4px;
}

.top-right-menu-item .contHover div .countCon {
    position: absolute;
    top: 0px;
    left: 81px;
    color: #fff;
    font-size: 9px;
    font-weight: 500;
}

.top-right-menu-item .contHover div p {
    margin-bottom: 0;
    font-weight: 400;
}

.top-right-menu-item .contHover div .bold {
    font-weight: 600;
/*padding-right: 5px;*/
}

.dropdown-content .contHover:hover {
    background-color: #F5F6FF;
    border-radius: 0;
    border-bottom: solid 1px #ddd;
}

.top-right-menu-item .contHover:hover {
    color: #000;
}

.imgBg, .pay, .tax {
    background: url(../img/images/new-svg/update-bg.svg) no-repeat;
    width: 90px;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 14px;
    background-size: cover;
    background-position: center;
    padding: 2px;
    cursor: pointer;
}

.sale {
    background: url(../img/images/new-svg/blue-bg.svg) no-repeat;
    font-size: 11px;
    font-weight: 400;
    padding: 3px;
    background-position: center;
    background-size: cover;
}

.pay {
    background: url(../img/images/new-svg/blue-bg.svg) no-repeat;
    font-size: 11px;
    font-weight: 400;
    background-size: cover;
    background-position: center;
    padding: 3px;
}

.tax {
    background: url(../img/images/new-svg/black-bg.svg) no-repeat;
    font-size: 11px;
    font-weight: 400;
    background-size: cover;
    background-position: center;
    padding: 3px;
}

.msg-bg {
    background: url(../img/images/new-svg/msg.svg) no-repeat;
    display: inline-block;
    text-align: center;
    line-height: 2;
    position: absolute;
    width: 48px;
    height: 24px;
    top: -8px;
    right: -16px;
    font-size: 11px;
    font-weight: 400;
    color: #fff;
    background-size: cover;
    background-position: center;
}

.msg-bg2 {
    background: url(../img/images/new-svg/msg.svg) no-repeat;
    text-align: center;
    float: left;
    line-height: 2;
    width: 22px;
    color: #fff;
    height: 20px;
    overflow: hidden;
    background-position: center;
    margin-left: 5px;
    background-size: cover;
}

.msg-bg.client {
    position: inherit;
    right: 0;
    top: 0;
    left: 0;
    font-size: 12px;
    width: 62px;
    line-height: 1.9;
}

.secoWidth {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 17px;
    padding-left: 17px;
}

/*client-page-css-start*/

.bottom-sec .clietLogo {
    width: 23px;
    height: 26px;
    background: transparent;
    opacity: 0.47;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 4px;
}

.bottom-sec .top-sec h2 {
    color: #3C4040;
    font-size: 22px;
    margin: 0;
}

.top-sec h2 {
    margin-top: 5px;
}

.bottom-sec .top-sec {
    vertical-align: middle;
}

.bottom-sec .year .dropdown-content {
    /*text-align: center;
    min-width: 100px;*/
  display: inline-block;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 10px 30px 10px 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
}

.bottom-sec .year .dropdown-content:after {
    background-color: #fcfcfc;
    padding: 6px;
    position: absolute;
    top: -7px;
    /* box-shadow: 0px -5px 16px 0px rgba(0,0,0,0.1); */
    left: 35px;
    transform: rotate(45deg);
    content: "";
    /* border: solid 1px #ddd; */
    display: inline-block;
    z-index: 99999;
}

.bottom-sec .year .dropdown-content p {
    padding: 8px;
    border-bottom: solid 1px #ddd;
    margin-bottom: 0;
    font-size: 12px;
    color: #565F5F;
}
.bottom-sec .year .chart-custom-select select {
    border: none;
    font-size: 12px;
}
.bottom-sec .year .chart-custom-select::after {
    content: "\f078";
    font-family: FontAwesome;
    position: absolute;
    top: 13px;
    right: 13px;
    /* width: 22px; */
    /* height: 15px; */
    font-size: 12px;
    line-height: 10px;
    color: #585959;
    pointer-events: none;
    text-align: center;
}

.bottom-sec .chart-custom-select::after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    top: 7px;
    right: 5px;
    font-size: 12px;
    line-height: 10px;
    color: #585959;
    pointer-events: none;
    text-align: center;
}
.bottom-sec .Searchbg {
    width: 320px;
    position: relative;
}
/* CPASAGE TEAM - removed bg color and make the edge rounded to match with other profile box*/
.bottom-sec .Searchbg input {
    /*background-color: #F5F6FF;*/
    height: 35px;
    font-size: 12px;
    position: relative;
    border-radius: 5px;
}

.bottom-sec .Searchbg i {
    position: absolute;
    border: 0;
    right: 10px;
    font-size: 17px;
    color: #565F5F;
    top: 8px;
}
.bottom-sec .no-bg {
    background: url(../img/images/client-icon/number-bg.svg) no-repeat;
    width: 37px;
    height: 37px;
    display: inline-block;
    text-align: center;
    color: #fff;
    line-height: 2.3;
    margin-top: 5px;
}
.f-right {
    float: right;
}

.f-left {
    float: left;
}
/*.nav-tabs {
    border-bottom: none;
}*/

.bottom-sec .rightFlex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.bottom-sec .nav-tabs>li {
    margin-bottom: 0;
    margin-right: 25px;
}

.bottom-sec .nav-tabs>li>a {
    color: #939F9D;
    border: none;
}

.bottom-sec .nav-tabs>li>a.active, .nav-tabs>li>a.active:focus, .nav-tabs>li>a.active:hover {
    color: #4b5ca9;
    cursor: default;
    background-color: #fff;
    border-bottom: 2px solid #4b5ca9;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.bottom-sec .nav>li>a:focus, .nav>li>a:hover {
    background: transparent;
    color: #4b5ca9;
    border: 0px;
    border-bottom: 2px solid #4b5ca9;
}
/* new added style */
.nav>li>a {
    position: relative;
    display: block;
    padding: 8px;
}

.bottom-sec .circleImg {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 4px;
}

.bottom-sec .tableDrop p {
    padding: 0 6px;
    display: inline-block;
    margin-bottom: 0;
    width: 105px;
}

/*table-css*/
/* CPASAGE - added shadow to the table */


.bottom-sec .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    vertical-align: middle;
}

.bottom-sec .table>tbody>tr>td {
    border-top: 0;
    border-bottom: solid 1px #ddd;
    padding: 10px 12px;
    font-size: 12px;
    white-space:pre-wrap;
}
/* CPASAGE TEAM - table bottom border and added some color to table header*/
.bottom-sec .table>thead>tr>th {
    vertical-align: middle;
    font-size: 12px;
    /*border-bottom: 0;*/
    padding-bottom: 12px;
    /*border: 0;*/
   /* background-color: #f5f6ff;
    border-color: #eff2f7;*/
    
}

.bottom-sec .table-hover>tbody>tr:hover {
    background-color: transparent;
    background: transparent linear-gradient(90deg, #e0e7fa 0%, #2C4B9B66 100%) 0% 0% no-repeat padding-box;
}

.bottom-sec .duePic {
    margin-left: 5px;
    width: 60px;
    display: inline-block;
}

.bottom-sec .duePic img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.bottom-sec .tableDroSec .dropdown-content {
    text-align: left;
    min-width: 200px;
}

.bottom-sec .tableDroSec .dropdown-content p span {
    margin-right: 6px;
}

.bottom-sec .tableDroSec .dropdown-content p {
    margin-bottom: 0;
    padding: 10px;
    border-bottom: solid 1px #ddd;
}

/*client-page-css-end*/

/*--- Dashboard page chart css start ----*/

.dashboard-chart {
    width: 100%;
    height: auto;
}

.page-heading {
    font-size: 25px;
    font-weight: 500;
    color: #313131;
    margin: 0;
    padding: 15px;
    line-height: 1;
}

.chart-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
/* CPASAGE TEAM - added rounded edge */
.chart-item {
    width: 100%;
    height: 100%;
    display: block;
    background-color: #ffffff;
    position: relative;
    padding: 20px 35px;
    transition: all 0.3s linear 0s;
    border-radius: 12px
}
/*
.chart-item:hover, .chart-item.active:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    ;
}*/

.chart-item:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.chart-item.top-line::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    transition: all 0.3s linear 0s;
    left: 35px;
    width: 0%;
    height: 2px;
    background-color: transparent;
}

.chart-item.top-line:hover::after{
    width: 130px;
    height: 2px;
    display: inline-block;
}

/*
.chart-item.active {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}*/

.chart-header {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.chart-title {
    line-height: 1;
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 500;
    color: #2C4B9B;
}

.chart-custom-select {
    position: relative;
    width: 70px;
}

.chart-custom-select::after {
    content: "";
    font-family: FontAwesome;
    position: absolute;
    top: 7px;
    right: 5px;
    width: 10px;
    height: 10px;
    font-size: 10px;
    line-height: 10px;
    color: #585959;
    pointer-events: none;
    text-align: center;
}

.chart-custom-select select {
    width: 100%;
    display: block;
    padding: 5px 10px;
    font-size: 10px;
    color: #585959;
    outline: none;
    border-radius: 3px;
    border: solid 1px #ddd;
}

.chart-body {
    width: 100%;
    min-height: 220px;
    height: calc(100% - 26px);
    display: flex;
    align-items: center;
    padding-top: 7px;
}
.pl-33 {
    padding-left: 33px;
        
}

.pt-28{
    padding-top: 28px;
}

.pt-25 {
    padding-top: 25px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-8 {
    padding-top: 8px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.chart-item.chart-notes {
    background: url(../img/images/notes-bg.svg) no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}

.edit-notes {
    background-color: transparent;
    padding: 0px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.edit-notes img {
    width: 20px;
    display: block;
}

.chart-item.chart-notes .chart-header .chart-title {
    color: #ffffff;
}

.edit-notes-box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 6px;
    padding: 15px;
    overflow-y: auto;
    resize: none;
    color: #000000;
    font-size: 10px;
    line-height: 1.5;
    outline: none;
}

/*--- Dashboard page chart css end ----*/

/*---- Task page css start ----*/

.contact-detail {
    display: flex;
    flex-wrap: wrap;
    padding-left: 31px;
}
/* duplicate code - commmeted by CPA SAGE TEAM
.contact-box{
    width: 100%;
    height: 100%;
    padding: 20px 20px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px #00000029;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    transition: all 0.3s linear 0s;
    position: relative;
    position: relative;
}*/

.contact-box::after {
    content: "";
    display: block;
    width: 0px;
    height: 2px;
    background-color: #4B5CA9;
    position: absolute;
    bottom: 0;
    left: 18px;
    transition: all 0.3s linear 0s;
}

.contact-box:hover::after, .contact-box.active::after {
    width: 30px;
}

.contact-box img {
    width: 25px;
    margin-right: 10px;
}

.contact-box img.inactive {
    display: block;
}

.contact-box img.active {
    display: none;
}

.contact-box p {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    line-height: 1;
    margin: 0;
    transition: all 0.3s linear 0s;
    word-break: break-word;
}

.contact-box:hover, .contact-box.active {
    text-decoration: none;
}

.contact-box:hover img.inactive, .contact-box.active img.inactive {
    display: none;
}

.contact-box:hover img.active, .contact-box.active img.active {
    display: block;
}

.contact-box:hover p, .contact-box.active p {
    color: #4B5CA9;
}

.notesBox {
    width: 100%;
    font-size: 12px;
}

.heading-box {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.heading-box h6 {
    font-size: 25px;
    color: #424242;
    line-height: 1.3;
    font-weight: 600;
    margin: 0;
    padding-right: 15px;
}

.heading-box img {
    width: 25px;
    height: 25px;
    display: block;
    margin-right: 35px;
}
/*
.my-task-box {
    /*padding-top: 20px;
}*/

.custom-checkbox-radio {
    position: relative;
    display: inline-flex;
    flex-wrap: nowrap;
    padding: 0px 0px 5px 20px;
}

.custom-checkbox-radio .label--checkbox, .custom-checkbox-radio .label--radio {
    position: relative;
    cursor: pointer;
    display: block;
    color: #2c2c2c;
    font-size: 12px;
    margin: 0;
    line-height: 1.5;
    font-weight: 400;
}

.custom-checkbox-radio .checkbox {
    position: relative;
    top: 3px;
    cursor: pointer;
    margin: 0 15px 0 -21px;
}

.custom-checkbox-radio .checkbox:after {
    content: "";
    position: absolute;
    top: -3px;
    left: -2px;
    width: 20px;
    height: 20px;
    background: #EEF3FF;
    border: solid 0px #555;
    cursor: pointer;
    border-radius: 50%;
}

.custom-checkbox-radio .checkbox:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg) scale(0, 0);
    -moz-transform: rotate(-45deg) scale(0, 0);
    -ms-transform: rotate(-45deg) scale(0, 0);
    -o-transform: rotate(-45deg) scale(0, 0);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 2px;
    top: -2px;
    z-index: 1;
    width: 16px;
    height: 8px;
    border: solid 2px #707070;
    border-top-style: none;
    border-right-style: none;
}

.custom-checkbox-radio .checkbox:checked:before {
    -webkit-transform: rotate(-45deg) scale(1, 1);
    -moz-transform: rotate(-45deg) scale(1, 1);
    -ms-transform: rotate(-45deg) scale(1, 1);
    -o-transform: rotate(-45deg) scale(1, 1);
    transform: rotate(-45deg) scale(1, 1);
}

.custom-checkbox-radio .checkbox:checked::after {
    background-color: #EEF3FF;
    border-color: #EEF3FF;
}

.flexStart {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.left-task-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.task-icon {
    width: 40px;
    height: 40px;
    background-color: #4B5CA9;
    padding: 0px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
}

.task-icon img {
    width: 100%;
    height: 100%;
    display: block;
}

.task-content p {
    font-size: 11px;
    color: #5C6B8A;
    font-weight: 400;
    margin: 0;
    padding-top: 5px;
}

.task-content p i {
    font-weight: 500;
    color: #5C6B8A;
}

.task-dots span {
    width: 5px;
    height: 5px;
    background-color: #B20202;
    display: inline-block;
    margin: 1px;
    border-radius: 50%;
}

.task-status {
    font-size: 11px;
    color: #B20202;
    padding-left: 15px;
}

/* Duplicate code - commented by CPA SAGE TEAM
.chat-header {
    width: 100%;
    min-height: 67px;
    background-color: #4B5CA9;
    padding: 24px 13px;
    text-align: center;
    position: relative;
    border-radius: 11px 11px 0 0;
}
*/
.header-title {
    position: relative;
    z-index: 9;
    font-size: 15px;
    font-weight: 600;
    color: #555252;
    margin: 0;
    margin-left: 10px;
    display: inline;

    /* right: 28px; */
}

.msg-items.current-user-msg .user-profile {
    color: #fff;
    background: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat;
}

.msg-items.current-user-msg .msg-box-container {
    background-color: #d3d8f2;;
}

.box-col {
    padding-bottom: 10px;
}

.chat-header .bubbleBase {
    top: -60px;
    left: 3%;
}

.chat-header .bubbleBase img {
    width: 80px;
}

.chat-header .bubbleBase2 {
    top: 0px;
    left: 60%;
}

.chat-header .bubbleBase2 img {
    width: 15px;
}

.chat-header .bubbleBase3 {
    bottom: -7px;
    left: 30%;
    top: auto;
    right: auto;
}

.chat-header .bubbleBase3 img {
    width: 25px;
}

.chat-header .bubbleBase4 {
    bottom: -70px;
    left: auto;
    top: auto;
    right: 5%;
}

.chat-header .bubbleBase4 img {
    width: 100px;
}

.chat-container:hover .bubbleBase {
    transform: translate(20px, -50px);
}

.chat-container:hover .bubbleBase2 {
    transform: translate(0px, -50px);
}

.chat-container:hover .bubbleBase3 {
    transform: translate(0px, 50px);
}

.chat-container:hover .bubbleBase4 {
    transform: translate(0px, 100px);
}

.contact-detail {
    padding-bottom: 0px;
}

@media screen and (min-width: 1200px) {
    .contact-detail {
        /* padding: 0 200px 30px 0;*/
        padding: 0 30px 30px 0;
    }
    .box-col {
        padding-right: 50px;
    }
}

@media screen and (max-width: 1200px) {
    .msg-wrapper {
        padding-left: 30px;
    }
}

@media screen and (max-width: 767px) {
    .mobile-msg-wrapper {
        padding-left: 30px;
    }
}

/*new-css*/

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #fcfcfc;
    min-width: 160px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content .contHover {
    color: black;
    padding: 7px 16px;
    text-decoration: none;
    display: block;
    font-size: 13px;
    border-bottom: solid 0.5px #ddd;
}

.contHover a{
   color: #495057fc;
}

.hoverClickImg {
    width: 25px;
    margin-right: 6px;
}





.labelImg {
    width: 75px;
}

/*switch*/
.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #fff;
}

/*
input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}
*/

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: green;
    box-shadow: none;
}

.notifiCli {
    position: relative;
}

.notifiCli img {
    width: 33px;
}

.notifiCli .countCon {
    position: absolute;
    top: 4px;
    left: 13px;
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.swichSec {
    padding: 0 10px;
}

.rotate-icon {
    margin-left: 15px;
}

/*[data-toggle="collapse"] .rotate-icon:before {  
  content: "\f078";
}

[aria-expanded="true"] .rotate-icon:before {  
  content: "\f077";
}

[data-toggle="collapse"].collapsed .rotate-icon:before {
  content: "\f078";
}
*/

.panel-title {
    position: relative;
}

.panel-group .panel+.panel {
    margin-top: 0;
}

.panel-title::after {
    content: "\f077";
    color: #fff;
    top: 2px;
    right: 1px;
    position: absolute;
    font-family: "FontAwesome"
}

.panel-group .panel {
    margin-bottom: 0;
    padding-left: 0;
    border-radius: 4px;
    border: none;
}

.panel-default {
    border-color: none;
}

.panel-title[aria-expanded="true"]::after {
    content: "\f078";
}

/*--- Task page css end ---*/

/*---- popup ui with form element css start  ----*/

/* CPA SAGE - Slide out Modal */



/* Contractor - provided style */

/*.custom-modal .row {
    display: flex;
    flex-wrap: wrap;
}



.custom-modal .modal-dialog {
    min-width: 500px;
    width: auto;
    max-width: 1500px;
    margin: 0px auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%) !important;
    display: inline-block;
}*/
.modal .modal-body {
    padding: 33px 40px;
    width: 100%;
    max-height: calc(100vh - 250px);
    overflow-y: auto;
}

.modal .modal-footer {
    padding: 20px 40px;
    /*justify-content: space-between;*/
}

.modal .modal-dialog .modal-content {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0em;
    border-radius: 22px;
}

.modal .modal-header {
    background: #5665ab;
    padding: 50px 70px;
    border-radius: 20px 20px 0 0;
    position: relative;
}
.modal-header-content {
    width: 100%;
    position: relative;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}
/* CPASAGE - added to make the animation top to bottom */
.modal .modal-content {
    position: relative;
    width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
  from {top:-400px; opacity:0} 
  to {top:0px; opacity:1}
}

@keyframes animatetop {
  from {top:-400px; opacity:0}
  to {top:0px; opacity:1}
}

/* CPASAGE - slideout left to right currently not using 
.modal-dialog-slideout {
    min-height: 50%;
    margin: 0 auto 0 0;
    background: #fff;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(-100%, 0%);
    transform: translate(-100%, 0);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0, 30%);
    transform: translate(0, 30%);
    flex-flow: column;
}

.modal-dialog-slideout .modal-content {
    border-radius: 20px 20px 0 0;
    border-radius: 22px;
}*/

::placeholder{
    color: #6c757deb;
}
.modal .modal-content .modal-close {
    position: absolute;
    top: -30px;
    right: 30px;
    z-index: 999;
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 5px;
    color: #fff;
    opacity: 0.8;
   transition: all 0.3s linear 0s;
}

.modal .modal-content .modal-close:hover {
    opacity: 1;
}

.modal .modal-header .bubbleBase {
    position: absolute;
    top: 64px;
    left: 35%;
}
.modal .modal-header .bubbleBase img{
    width: 110px;
}

.modal .modal-header .bubbleBase1 {
    position: absolute;
    top: -70px;
    left: -10%;
}

.modal .modal-header .bubbleBase2 {
    position: absolute;
    left: 70%;
    top: -35px;
}

.modal .modal-header .bubbleBase3 {
    position: absolute;
    left: 88%;
    top: 76px;
}

.modal .modal-header:hover .background-circle-animation .bubbleBase {
    transform: translate(0px, 72px);
    transition: transform 0.5s linear 0s;
}

.modal .modal-header:hover .background-circle-animation .bubbleBase1 {
    transform: translate(-18px, -171px);
    transition: transform 0.5s linear 0s;
}

.modal .modal-header:hover .background-circle-animation .bubbleBase2 {
    transform: translate(0px, -34px);
    transition: transform 0.5s linear 0s;
}

.modal .modal-header:hover .background-circle-animation .bubbleBase3 {
    transform: translate(0px, 170px);
    transition: transform 0.5s linear 0s;
}
a:hover{
    text-decoration: none;
}

.background-circle-animation {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-color: transparent;
    z-index: 0;
    border-radius: 20px 20px 0 0;
}

/* Modal code end */

/* Modal differnet size */
/* popup-small-css */
  .custom-small .modal-sm{
   width: 350px!important;
  }
  .custom-small .modal-header{
      padding: 40px;
  }
  .circle2-wid120{
      width: 120px;
  }
  .modal .modal-sm .modal-header .bubbleBase3 {
    position: absolute;
    left: 88%;
    top: 76px;
}
.modal .modal-sm .modal-body {
    padding: 33px 40px;
    width: 100%;
    min-height: calc(100vh - 445px);
    overflow-y: auto;
}
/* popup-small-css */

  /* popup-medium-css */
  .custom-small .modal-md{
    width: 650px!important;
  }
  
.modal .modal-md .modal-body {
    padding: 33px 40px;
    width: 100%;
    min-height: calc(100vh - 345px);
    overflow-y: auto;
}
  .custom-small .modal-header{
      padding: 40px;
  }
    /* popup-medium-css */

        /* popup-large-css */
        .custom-small .modal-lg{
            width: 950px!important;
          }
          
        .modal .modal-lg .modal-body {
            padding: 33px 40px;
            width: 100%;
            /*min-height: calc(100vh - 300px);*/
            overflow-y: auto;
        }
          .custom-small .modal-header{
              padding: 40px;
          }
        /* popup-large-css */

/* popup-variation-css */

.left-search-text {
    padding: 0 20px 0 0;
}

.left-search-text p {
    font-size: 22px;
    line-height: 27px;
    color: #D9E1FF;
    margin: 0;
    font-weight: 400;
}

.left-search-text p.search-by-text {
    color: #fff;
}

.left-search-text p.search-by-text i {
    font-style: italic;
    font-weight: 700;
}

.middle-select-item {
    padding: 0 0 0 15px;
}

.table-list-count {
    padding: 8px 20px 8px 20px;
    background-color: #fff;
    border-radius: 3px;
    color: #4B5CA9;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    position: relative;
    text-align: center;
    margin-left: 20px;
}

.select select {
    padding: 8px 40px 8px 20px;
    background-color: #fff;
    border-radius: 3px;
    color: #4B5CA9;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    outline: none;
    position: relative;
}

.right-search-box {
    padding-left: 20px;
}

.right-search-box input {
    width: 200px;
    display: block;
    padding: 8px 15px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    border: 0;
    border-bottom: solid 1px #ccc;
    border-radius: 0;
    outline: none;
    background-color: transparent;
}

.right-search-box input::placeholder {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
}


.custom-table .table {
    width: 100%;
}

.custom-table .table thead, .custom-table .table thead tr, .custom-table .table thead th, .custom-table .table tbody, .custom-table .table tbody tr, .custom-table .table tbody td {
    border: 0;
}

.custom-table .table th {
    padding: 10px 15px;
    color: #606060;
    font-weight: 600;
}


.custom-table .table thead:hover {
    background: none;
}

.custom-table .table tbody tr.active, .custom-table .table tbody tr:hover {
    background: transparent linear-gradient(90deg, rgba(75, 92, 169, 0.15) 0%, rgba(75, 92, 169, 0.05) 100%) 0% 0% no-repeat padding-box;
}

.custom-table .table tbody tr.active td, .custom-table .table tbody tr:hover td, .custom-table .table tbody tr td {
    background-color: transparent;
}

.custom-table .table tr td {
    padding: 10px 15px;
    color: grey;
}



.namefont {
    color: #4B5CA9;
    font-weight: 500;
}

.mr-15 {
    margin-right: 15px;
}




.btn-primary {
    /*padding: 14px 15px;*/
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    line-height: 21px;
    background: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat padding-box;
    outline: none;
    border: 0;
    border-radius: 3px;
    transition: all 0.3s linear 0s;
}

.btn-primary:hover {
    /*padding: 14px 15px;*/
    font-size: 15px;
    font-weight: 500;
    color: #5060AB;
    line-height: 21px;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    outline: none;
    border: 0;
    border-radius: 3px;
}

.btn-secondary {
    /*padding: 14px 15px;*/
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    line-height: 21px;
    background: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat;
    outline: none;
    border: 0;
    border-radius: 3px;
    transition: all 0.3s linear 0s;
}

.btn-secondary:hover {
    /*padding: 14px 15px;*/
    box-shadow: 0px 3px 6px #00000029;
    /*
    font-size: 15px;
    font-weight: 500;
    color: #5060AB;
    line-height: 21px;
    background: #fff;
    outline: none;
    border: 0;
    border-radius: 3px;
    */
}

.btn-secondary-cpasage {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    background: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat;
    box-shadow: 0px 0px 6px #00000029;
    outline: none;
    border: 0;
    border-radius: 3px;
    transition: all 0.3s linear 0s;
    margin-top: 5px;
    margin-right: 40px;
    margin-bottom: 6px;
    margin-left: 30px;
}

/*.btn-secondary-cpasage:hover {
    font-size: 15px;
    font-weight: 500;
    color: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat;
    background: white;
    outline: none;
    border: 0;
    border-radius: 3px;
    margin-top: 5px;
    margin-right: 40px;
    margin-bottom: 3px;
    transform: translateY(-1px);
}*/

.btn-secondary-cpasage:disabled {
    color: #a9a9a9;
    opacity:.45;
}
/* CPASAGE TEAM - added rounded button for add button*/
.btn-secondary-add-cpasage {
    font-size: 15px;
    font-weight: 500;
    color: #5060AB;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    outline: none;
    border: 0;
    border-radius: 50px;
    transition: all 0.3s linear 0s;
    margin-top: 5px;
    margin-left: 24px;
    margin-bottom: 5px;
}

.btn-secondary-add-cpasage:hover {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    background: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat padding-box;
    outline: none;
    border: 0;
    border-radius: 50px;
    margin-top: 5px;
    margin-left: 24px;
    margin-bottom: 5px;

}

.btn-secondary-add-cpasage:disabled {
    color: #a9a9a9;
    opacity:.45;
}

.circle2-wid200 {
    width: 200px;
}

.circle-opacity {
    opacity: 0.2;
}

.custom-table .table-header-sticky {
    width: 100%;
    position: relative;
    border-collapse: collapse;
}

.custom-table .table-header-sticky thead {
    background-color: #fcfcfc;
}

.custom-table .table-header-sticky thead tr th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}
.task-checkbox .form-group{
    padding-bottom: 0;
}
.form-group {
    /*width: 100%;*/
    display: block;
    position: relative;
    /*padding-bottom: 25px;*/
    margin: 0;
}

.form-group .form-label {
    font-size: 12px;
    color: #2c2c2c;
    margin: 0;
    display: block;
    line-height: 1.5;
    font-weight: 400;
    padding: 0 10px;
}
/* CPA SAGE TEAM - dropdown sty;ing used in chart dropdown*/
.form-group .form-control {
    padding: 8px 10px;
    /*outline: none;*/
    background: #fff;
    border: 0;
    border-radius: 0;
   /* box-shadow: 0px 3px 6px #00000029;*/
    color: #4c509b;
    display: block;
    height: auto;
    font-weight: 500;
    font-size: 12px;
    transition: all 0.3s linear 0s;
    cursor: pointer;
    /*border-bottom: solid 1px #2c2c2c;*/
    line-height: 20px;
    /*appearance: none;*/
    /*min-width: 200px;
    width:min-content;*/
    
}
/* CPASAGE Team - extended boostrap class for the dropdown for website*/
.form-group-dropdown .form-control-dropdown{
    padding: 5px 10px;
    color: #6c757deb;
    font-weight: 500px;
    font-size: 15px;
   

}

.form-group select.form-control option:hover{
    background-color: red;
} 
*/
option:hover{background-color:yellow;}

.form-group .form-control::placeholder {
    font-size: 14px;
    color: #2c2c2c;
    font-weight: 400;
}

/*.form-group .form-control:focus {
   /* border-color: #4B5CA9;
}*/

.select, .custom-date-picker {
    position: relative;
}

.form-group .select {
    display: block;
    width: 100%;
    height: auto;
    padding: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: transparent;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select::after {
    content: "";
    font-family: FontAwesome;
    position: absolute;
    top: 8px;
    right: 10px;
    width: 24px;
    height: 24px;
    font-size: 20px;
    line-height: 24px;
    color: #777;
    pointer-events: none;
    text-align: center;
}

.form-group .form-control[type="date"] {
    position: relative;
}

.form-group .form-control[type="date"]::before {
    content: "\f133";
    font-family: FontAwesome;
    position: absolute;
    top: 8px;
    right: 10px;
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #777;
    pointer-events: none;
    text-align: center;
}

/*
.form-control {
    padding: .275rem .75rem;
    margin-top: 5px;
 }
 */

 .select {
  
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  /*box-shadow: 0 0 6px 0 rgba(0,0,0,0.1);*/
  background-color: #ffffff;
 

 }
.form-group .form-control[type="date"]::-webkit-inner-spin-button, .form-group .form-control[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .form-group input[type=date].form-control, .form-group input[type=datetime-local].form-control, .form-group input[type=month].form-control, .form-group input[type=time].form-control {
        line-height: 24px;
    }
}

.custom-checkbox-radio .label--checkbox, .custom-checkbox-radio .label--radio {
    position: relative;
    cursor: pointer;
    display: block;
    color: #2c2c2c;
    font-size: 15px;
    margin: 0;
    line-height: 1.5;
    font-weight: 400;
}



.custom-checkbox-radio .checkbox:after {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: solid 2px #555;
    cursor: pointer;
}

.custom-checkbox-radio .checkbox:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg) scale(0, 0);
    -moz-transform: rotate(-45deg) scale(0, 0);
    -ms-transform: rotate(-45deg) scale(0, 0);
    -o-transform: rotate(-45deg) scale(0, 0);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    z-index: 1;
    width: 13px;
    height: 8px;
    border: solid 3px #ffffff;
    border-top-style: none;
    border-right-style: none;
}

.custom-checkbox-radio .checkbox:checked:before {
    -webkit-transform: rotate(-45deg) scale(1, 1);
    -moz-transform: rotate(-45deg) scale(1, 1);
    -ms-transform: rotate(-45deg) scale(1, 1);
    -o-transform: rotate(-45deg) scale(1, 1);
    transform: rotate(-45deg) scale(1, 1);
}

.custom-checkbox-radio .checkbox:checked::after {
    background-color: #4B5CA9;
    border-color: #4B5CA9;
}

.custom-checkbox-radio .radio {
    position: relative;
    margin: 0 15px 0 0;
    cursor: pointer;
    top: 5px;
}

.custom-checkbox-radio .radio:after {
    content: "";
    position: absolute;
    top: -5px;
    left: -2px;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: solid 2px #555;
    border-radius: 50%;
}

.custom-checkbox-radio .radio:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -moz-transition: -moz-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: -1px;
    left: 2px;
    z-index: 1;
    width: 12px;
    height: 12px;
    background: #4B5CA9;
    border-radius: 50%;
}

.custom-checkbox-radio .radio:checked:before {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

.custom-checkbox-radio .radio:checked::after {
    border-color: #4B5CA9;
}


/*---- popup ui with form element css end  ----*/
/* -----table head fix -----*/
.tableFixHead {
    overflow-y: auto;
    /*height: 150px;*/
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
}

.tableFixHead thead th {
    background: #eee;
}
.customCheck .checkbox:after {
    border-radius: inherit;
}

/* login-css */

.loginpageSec {
    width: 100%;
    height: 100vh;
    display: block;
    background-color: #ddd;
}

.leftBgClr {
    width: 50%;
    display: block;
    /* background-color: #283B72; */
    position: relative;
    height: 100%;
    /* transform: matrix(-1, 0, 0, -1, 0, 0); */
    background: transparent linear-gradient(90deg,#283B72 0%,#4B5CA9 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.rightBgClr {
    width: 50%;
    display: block;
    height: 100%;
    background-color: #fff;
    position: relative;
}

input:focus~.floating-label, input:not(:focus):valid~.floating-label {
    top: 1px;
    bottom: 10px;
    left: 15px;
    font-size: 11px;
    color: #666666;
    opacity: 0.75;
}

.inputText:focus {
    border-left: solid 3px #5A6AB0;
    outline: none;
}

.inputText {
    font-size: 12px;
    width: 100%;
    height: 30px;
    padding: 3px 12px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 3px 5px #00000024;*/
    border: 1px solid #DCDCDC;
}
/* CPA SAGE - added style to avoid overflow visible*/
input[type="file"]{
    overflow: hidden;
    font-size: 12px;
}

.floating-label {
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 10px;
    transition: 0.2s ease all;
    font-size: 13px;
}

.loginCont {
    width: 52%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* margin: 160px 30px 10px 152px; */
}

.typeInput {
    list-style: none;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 0;
}
.typeInputBuss {
    padding: 0px 14px;
    margin-bottom: -10px;

}

.typeInput li {
    position: relative;
}

.loginCont h2 {
    background: -webkit-linear-gradient(#4B5CA966, #4B5CA9);
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 800;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    text-transform: uppercase;
}

.italicFont {
    font-size: 13px;
    font-style: italic;
    margin-top: 3px;
    margin-bottom: 8px;
    color: #7C7C7C;
}

.radioclick label {
    font-size: 12px;
    color: #666;
    top: -2px;
    position: relative;
    padding-left: 2px;
}

.loginLogo {
    position: absolute;
    width: 150px;
    height: 94px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loginLogo img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
}

.leftBgClr .bubbleBase {
    position: absolute;
    top: -32px;
    left: -39px;
}

.leftBgClr .bubbleBase2 {
    position: absolute;
    top: 13%;
    left: 190px;
}

.leftBgClr .bubbleBase3 {
    position: absolute;
    top: -30px;
    right: -35px;
}
.leftBgClr .bubbleBase img {
    width: 120px;
}
.leftBgClr .bubbleBase2 img{
    width: 140px;
}
.leftBgClr .bubbleBase3 img{
width: 150px;
}
.leftBgClr .bubbleBase6 img{
    width: 180px;
    }
    .leftBgClr .bubbleBase5 img{
        width: 120px;
        }
.leftBgClr .circle-opacity {
    opacity: 0.1;
}
.leftBgClr .bubbleBase4 {
    position: absolute;
    top: 41%;
    left: 104px;
}

.leftBgClr .bubbleBase5 {
    position: absolute;
    top: 71%;
    left: 70px;
}

.leftBgClr .bubbleBase6 {
    top: 59%;
    right: 68px;
    position: absolute;
}

.leftBgClr:hover .background-circle-animation .bubbleBase6 {
    transform: translate(210px, 0px);
    transition: transform 0.3s linear 0s;
}

.leftBgClr:hover .background-circle-animation .bubbleBase {
    transform: translate(0px, -200px);
    transition: transform 0.3s linear 0s;
}

.leftBgClr:hover .background-circle-animation .bubbleBase2 {
    transform: translate(-340px, 0px);
    transition: transform 0.3s linear 0s;
}

.leftBgClr:hover .background-circle-animation .bubbleBase3 {
    transform: translate(150px, 0px);
    transition: transform 0.3s linear 0s;
}

.leftBgClr:hover .background-circle-animation .bubbleBase4 {
    transform: translate(-150px, 0px);
    transition: transform 0.3s linear 0s;
}

.leftBgClr:hover .background-circle-animation .bubbleBase5 {
    transform: translate(-150px, 0px);
    transition: transform 0.3s linear 0s;
}
.rememberSec .label--checkbox{
    font-size: 13px;
    color: #666666;
}
.rememberSec .custom-checkbox-radio .checkbox:after{
width: 17px;
height: 17px;
}
.rememberSec .custom-checkbox-radio .checkbox{
margin: 0 8px 0 0;
}
.exitUs span{
    font-style: italic;
    color: #7C7C7C;
    padding-right: 3px;
}
.exitUs{
    font-size: 13px;
}
.exitUs a{
    color: #4B5CA9;
    font-weight: 600;
    text-decoration: none;
}
.eyeIcon img{
    width: 26px;
    height: 26px;
}
.eyeIcon {
    position: absolute;
    right: 12px;
    top: 10px;
}
.rememberSec .btn-primary {
    padding: 10px 50px;
    border-radius: 5px;
    text-transform: uppercase;
}

.rememberSec .btn-primary:hover {
    padding: 10px 50px;
    border-radius: 5px;
    border: 1px solid transparent;
}

.rememberSec .btn-secondary {
    padding: 10px 50px;
    border: 1px solid #4B5CA9;
border-radius: 5px;
text-transform: uppercase;
}

.rememberSec .btn-secondary:hover {
    padding: 10px 50px;
    border: 1px solid #4B5CA9;
}

/* login-css-end */

/* Project Styling  added  by CPASAGE Team*/

.cpasage-project-card {

    font-size: 18px;
    color: #424242;
    font-weight: 600;
}

.progress {
    width: 100%;
    text-align: center;
}

/* Badge Color - CPASAGE Team */

.badge {
    line-height: 1.50;
}

.badge-danger {
    background-color: #AD2b2B;
    color: #ffffff;
    border-radius: 50px;
}

.badge-warning {
    background-color: #FF9D00;
    color: #ffffff;
    border-radius: 50px;
    width: 60px;
}

.badge-success {
    background-color: #17aa1d;
    color: #ffffff;
    border-radius: 50px;
    width: 60px;
}

.badge-info {
    background-color: #8263B1;
    color: #ffffff;
    border-radius: 50px;
}

.badge-primary {
    background-color: #376c72;
    color: #ffffff;
    border-radius: 50px;
}

.badge-secondary {
    background-color: #CBA049;
    color: #ffffff;
    border-radius: 50px;
}

/*cpasage - example for status tracking code 

.card {
    z-index: 0;
    background-color: #ECEFF1;
    padding-bottom: 20px;
    margin-top: 90px;
    margin-bottom: 90px;
    border-radius: 10px
}

.top {
    padding-top: 40px;
    padding-left: 13% !important;
    padding-right: 13% !important
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: #455A64;
    padding-left: 0px;
    margin-top: 30px
}

#progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400
}

#progressbar .step0:before {
    font-family: FontAwesome;
    content: "\f10c";
    color: #fff
}

#progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    background: #C5CAE9;
    border-radius: 50%;
    margin: auto;
    padding: 0px
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 12px;
    background: #C5CAE9;
    position: absolute;
    left: 0;
    top: 16px;
    z-index: -1
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%
}

#progressbar li:nth-child(2):after,
#progressbar li:nth-child(3):after {
    left: -50%
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%
}

#progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
}

#progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: #651FFF
}

#progressbar li.active:before {
    font-family: FontAwesome;
    content: "\f00c"
}


 /*MEDIA ATTRIBUTES*/

@media screen and (max-width: 375px) {

    
    * {
        font-size: 12px;
    }
    

    .page-wrapper{

    display: flex;
    }

    .body-content-wrapper {
        padding: 0px 0px;
    }


    .page-heading {
        margin-left: 10px;
    }
    .chart-item {
        padding: 20px 8px;
    }
    

    .menu-icon{
        display: block;
    }

    .top-left-menu-item-container {
        padding-bottom: 0px;

    }

    .top-left-menu-item .flecx {
        padding: 0px;
        min-width: 0px;
    }
    
    .pt-28{
            padding-top: 22px;
        }
    .nav-logo a span {
        display: none;
    }
    .bottom-sec .Searchbg {
        width: 150px;
    }
    .bottom-sec .nav-tabs li {
        margin-bottom: 0px;
    }
    .nav-tabs .nav-item {
    margin-bottom: -8px;
    }

    .nav-tabs {
        border: none;
    }

    .btn-secondary-cpasage {
        margin-right: 19px;
        font-size: 12px;
    }

    .btn-secondary-cpasage:hover {
        font-size: 12px;
    }

    .form-group .form-control {
        font-size: 12px;
    }
    .custom-checkbox-radio {
        padding:1px 0px 5px 8px;
    }
    .menu-item {
        font-size: 10px;
    }
    .user-profile {
        display: none;
    }
    .msg-items {
        padding-left: 0px;
    }
    .panel-default-cpasage-client {
        width: 375px;
        left: 400px;
    }
    .panel-default-cpasage-team {
        width: 375px;
        left: 40px;
    }
    .header-title {
        right: 10px;
        font-size: 12px;
    }
    .chat-header {
        min-height: auto;
    }
    .msg-items::before {
        width: 0px;
    }
    
}

@media screen and (min-width: 376px) and (max-width:640px) {

        .page-wrapper{

        display: flex;
        }

        /*.right-body-content-wrapper{
            Width: calc(100% - 0px);
        }*/

        /*.right-top-header .top-left-menu-item .flecx span{
            display: none;
        }*/

       /* .top-left-menu-item-container {
            width: calc(100% - 0px);
        }
        .top-right-menu-item-container 
            padding-le;
            display: none;
        }*/

        .top-left-menu-item .flecx{
            padding: 0px;
            font-size: 11px;
            padding-left: 20px;
        
        }
        .nav-logo a span {
            display: none;
        }

        .menu-item a:hover span, .menu-item a.active span {
            font-size: 10px;
        }

        .dropdown-list{
            width:20rem!important;
        }

    }

    @media screen and (min-width: 641px) and (max-width: 768px) {

        .page-wrapper {

        display: flex;
        }


    .right-body-content-wrapper{
        Width: calc(100% - 0px);
    }

    .right-top-header .top-left-menu-item .flecx span{
        display: none;
    }

    .top-left-menu-item .flecx{
        padding: 0px;
        font-size: 11px
    }
    .nav-logo a span {
        display: none;
    }

    .menu-item a:hover span, .menu-item a.active span {
        font-size: 10px;
    }

    .col-md-3 .col-md-4 .col-md-12 {
        flex: 0%;
        max-width: 0%;
    }

}
/* CPASAGE - Loading screen added */
/* CPASAGE - Absolute Center CSS Spinner */
.spinner {
    position: fixed;
    margin: auto;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;

 }
  
/* CPA SAGE - alert styling*/
.alert {
    margin:20px auto;
    padding:30px;
    border-radius:5px;
    box-shadow:0 0 15px 5px #ccc;
    display: inline-block;
}

.alert-warning {
    width: 15%;
    background-color: hsla(0, 0%, 99%, 0.993);
    border-left: 5px solid 	#AD2B2B;
    text-align: center;
    color: 	#AD2B2B;
}

.alert-success {
    width: 15%;
    background-color: hsla(0, 0%, 99%, 0.993);
    border-left: 5px solid 	#4B5CA9C5;
    text-align: center;
    color: #4B5CA9C5
}

/* CPA SAGE - NAV BAR */
.topbar{
    height: 3.375rem;
   background: Linear-gradient(90deg,#4b5ca9 40%,#4b5ca9c5 100%); 
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
}
 
/* CPA SAGE - tootltips */

.tooltip a {
    color: #4b5ca9;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
   
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: #ccc;
  color: rgb(199, 52, 52);
  text-align: left;
  border-radius: 10px;
  padding: 5px 1px;
 
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: 50px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
   
}

/* invoice-css-start */
/*
.plusCircle img{
    width: 36px;
    height: 36px;
    display: inline-block;
    background: transparent url('img/Group 702.png') 0% 0% no-repeat padding-box;
opacity: 1;
cursor: pointer;
}
*/
.innerTwo .nav-tabs li{
    position: relative;
}
.innerTwo .nav-tabs li .plusCircle{
    position: absolute;
    right: -53px;
    top: -8px;
}
.nav-tabs>li>a.active{
color: #434343;
}
.innerTwo .table tbody tr:hover, .innerTwo .table tbody tr td .eye img:hover {
    color: #4C5DA9;
    fill: #4C5DA9;
    cursor: pointer;
}
.innerTwo .table tbody tr td span{
    padding-right: 32px;
}
.eye img{
    width: 23px;
    height: 14px;
}
.delt img{
    width: 13px;
height: 16px;
}
.anotherCont .contact-box p{
    font-size: 12px;
}
.anotherCont .contact-box {
    padding: 14px 15px;
}
.anotherCont .copyIcon .active, .copyIcon .inactive {
    width: 38px;
}

.leftModalSec{
    width: 50%;
    height: 100vh;
    background: #f8f8f8;
}
.rightModalSec{
    width: 50%;
    height: 100vh;
    background: #fff;
    position: relative;
}
.rightModalSec .modal-close{
    position: absolute;
}
.modal.fullWidth {
    padding: 0 !important;
  }
  .modal.fullWidth .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
    overflow: hidden;
  }
  .modal.fullWidth .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal.fullWidth .modal-body {
    overflow-y: auto;
  }
  .leftModalSec .bubbleBase2 img{
    width: 90px;
}
.leftModalSec .bubbleBase1 img{
    width: 400px;
}
.rightModalSec .bubbleBase2 img{
    width: 160px;
}
.rightModalSec .bubbleBase1 img {
    width: 200px;
}
.leftModalSec .bubbleBase img, .rightModalSec .bubbleBase img{
    width: 220px;
}
.rightModalSec .bubbleBase{
    top: -5%;
    left: 67%;
    position: absolute
}
.rightModalSec .bubbleBase1{
    top: 58%;
    right: 390px;
    position: absolute;
}
.rightModalSec .bubbleBase2{
    top: 93%;
    left: 382px;
    position: absolute;
}
.rightModalSec:hover .background-circle-animation .bubbleBase {
    transform: translate(-85px, 0px);
    transition: transform 0.5s linear 0s;
}
.rightModalSec:hover .background-circle-animation .bubbleBase1 {
    transform: translate(-200px, 0);
    transition: transform 0.5s linear 0s;
}
.rightModalSec:hover .background-circle-animation .bubbleBase2 {
    transform: translate(140px, 0px);
    transition: transform 0.5s linear 0s;
}
.leftModalSec .bubbleBase {
    top: -17%;
    /* right: 78px; */
    left: -79px;
    position: absolute;
}
.leftModalSec .bubbleBase1 {
    top: 60%;
    right: 369px;
    position: absolute;
}
.leftModalSec .bubbleBase2 {
    top: 20%;
    left: 550px;
    position: absolute;
}

.leftModalSec:hover .background-circle-animation .bubbleBase {
    transform: translate(-85px, 0px);
    transition: transform 0.5s linear 0s;
}
.leftModalSec:hover .background-circle-animation .bubbleBase1 {
    transform: translate(-200px, 0);
    transition: transform 0.5s linear 0s;
}
.leftModalSec:hover .background-circle-animation .bubbleBase2 {
    transform: translate(140px, 0px);
    transition: transform 0.5s linear 0s;
}
.popupHeader{
    color: #4C5DA9;
    font-size: 30px;
    padding-bottom: 12px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}
.headerImg{
    position: absolute;
    top: 40px;
    left: 55px;
}
.headerImg img{
    width: 50px;
height: 60px;
}
.containerSec{
    padding: 55px 30px 25px 50px;
    position: relative;
}
.containerSec .form-control{
    background: transparent;
    font-size: 13px;
}
.containerSec .custom-checkbox-radio .checkbox:after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 14px;
    height: 14px;
    background: #ffffff;
    border: solid 2px #555;
    cursor: pointer;
}
.containerSec .custom-checkbox-radio .checkbox:before{
    content: "";
    position: absolute;
    left: 1px;
    top: 0px;
    z-index: 1;
    width: 11px;
    height: 8px;
}
.containerSec .custom-checkbox-radio .checkbox:checked::after {
    background-color: #4B5CA9;
    border-color: #4B5CA9;
}
.custom-checkbox-radio .checkbox:disabled::after {
    background-color: #008e3b;
    border-color:#008e3b;
}
.containerSec .custom-form-group .form-label{
    font-size: 16px;
    margin: 0;
    display: block;
    color: #707070;
    font-weight: 600;
    padding-left: 0;
}
.pb-0{
    padding-bottom: 0;
}
.containerSec .custom-form-group {
    padding-bottom: 28px;
}
.rightModalSec .modal-close{
    background: transparent;
    outline: none;
    border: transparent;
    position: absolute;
    right: 38px;
    top: 13px;
    font-weight: 600;
    font-size: 18px;
}
.rightPop{
    text-transform: uppercase;
    color: #3A3A3A;
    font-size: 14px;
}
.rightPop span{
    color: #3A3A3A;
    font-weight: 700;
    font-size: 25px;
    padding-top: 4px;
}
.popupHeader span{
    color: #606060;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    display: block;
    text-transform: capitalize;
}
.popupTable .table thead tr th{
    padding: 10px;
    font-size: 14px;
    color: #444444;
    font-weight: 600;
    text-align: center;
    border: none;
}
.popupTable .table tbody tr td{
    color: #474748;
    font-size: 13px;
    font-weight: 500;
    opacity: 0.72;
    border-bottom: solid 1px #ddd;
    border-top: none;
}
.popupTable .delt img {
    width: 11px;
    height: 15px;
}
.delt, .edit, .eye{
    cursor: pointer;
}
.popupTable .edit img{
    width: 14px;
    height: 14px
}
.btnGroup .btn-secondary{
    box-shadow: none;
    border: solid 1px #5060AB;
    padding: 8px 22px;
    border-radius: 3px;
    font-size: 13px;
}
.btnGroup .btn-secondary:hover{
    font-weight: 500;
    color: #fff;
    background: transparent linear-gradient(90deg, #4B5CA9 0%, #4b5ca9c5 100%) 0% 0% no-repeat padding-box;
    outline: none;
}
.btnClick{
    box-shadow: none;
    border-bottom: solid 1px #5060AB;
    padding: 8px 22px;
    font-size: 13px;
    color: #5060AB;
}
.totalGrand{
    list-style: none;
}
.totalGrand li{
    float: left;
    padding-right: 55px;
    text-transform: uppercase;
    font-size: 17px;
    color: #294D99;
    font-weight: 700;
}
.mt-50{
    margin-top: 50px;
}
.ml-15{
    margin-left: 13px;
}
.tableHead thead tr th{
    font-size: 15px!important;
    color: #294D99!important;
    font-weight: 700!important;
    text-transform: uppercase;
    padding-right: 0!important;
}

/* Making Event Cursor Pointer for FullCalendar */
.fc-event {
    cursor: pointer;
}

tr.strikeout,
tr.strikeout td {
    background: transparent url('../img/images/strike.png') 0 50% repeat-x;
    background-color:rgba(255,255,255,0.5);
}

  /* invoice-css-enfd */

/*
[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    position: absolute;
    bottom: -1.6em;
    left: 100%;
    padding: 4px 4px 4px 8px;
    color: #222;
    white-space: nowrap; 
    -moz-border-radius: 5px; 
    -webkit-border-radius: 5px;  
    border-radius: 5px;  
    -moz-box-shadow: 0px 0px 4px #222;  
    -webkit-box-shadow: 0px 0px 4px #222;  
    box-shadow: 0px 0px 4px #222;  
    background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0, #f8f8f8),color-stop(1, #cccccc));
    background-image: -webkit-linear-gradient(top, #f8f8f8, #cccccc);  
    background-image: -moz-linear-gradient(top, #f8f8f8, #cccccc);  
    background-image: -ms-linear-gradient(top, #f8f8f8, #cccccc);  
    background-image: -o-linear-gradient(top, #f8f8f8, #cccccc);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
}

[data-title] {
    position: relative;
}*/